export const Status = {
    ALL: "ALL",
    PENDING: 'PENDING',
    COMPLETED: 'COMPLETED',
    REJECTED: 'REJECTED',
    REFUNDED: 'REFUNDED',
    ACTIVE: 'Active',
    BLOCKED: 'Blocked',
    ADMIN: 'SUPER_ADMIN',
    ACCEPTED: 'ACCEPTED',
    SUB_ADMIN: 'SUB_ADMIN',
}

export const Market = {
    BINANCE: 'BINANCE',
    COINBASE: 'COINBASE',
}

export const RequiredField = {
    REQUIRED: 'This field is required',
    PASSWORDTEXT: 'Password must be 8 characters long and contain at least one uppercase letter, one lowercase letter, one number and one special character.',
    WHITESPACETEXT: '*Name should not contain white spaces.',
    FIFTYWORDLIMIT: 'Input should not be greater than 50 characters',
    FIVEHUNDREDWORDLIMIT: 'Input should not be greater than 500 characters',
    ONLYALPHABETS: '*Only Alphabets are Allowed.'
}

export const GameEnums = {
    NAME: 'Please enter your name',
    PACKAGE: 'Please enter your Package ID',
    PLAYSTORE: 'Please enter your PlayStore Link',
    APPLESTORE: 'Please enter your AppStore Link',
    HUAWEI: 'Please enter your Huawei Link',
    WEBGL: 'Please enter your WebGL Link',
    DESCRIPTION: 'Please enter your Description',
    SHORT_DESCRIPTION: 'Please enter your Short Description',
    GAME_INFO: 'Please enter your Game Info',
    GAME_REWARDS: 'Please enter your Game Rewards',
    HOW_TO_PLAY: 'Please enter How to play',
    PRICE: 'Please enter your Price',
    CURRENCY: 'Please enter your Currency',
}

export const PermissionStatus = {
    READ: 'Read',
    WRITE: 'Write',
}

export const GamesfiCurrentUser = {
    CURRENT_USER: 'gamesfi_current_user'
}

export const GeneralVariables = {
    DEBIT: 'debit',
}