import Axios from 'axios';
import { NotificationManager } from 'components/common/react-notifications';
import { apiUrl } from 'constants/defaultValues';
import { GamesfiCurrentUser } from 'helpers/Enums';

const axios = Axios.create({
  baseURL: apiUrl,
});

axios.interceptors.request.use(function (config) {
  const user = localStorage.getItem(GamesfiCurrentUser.CURRENT_USER);
  if (user) {
    config.headers.authorization = JSON.parse(user).token;
  }
  if (config.url === "/admin/follow/report" || config?.url === "/admin/referal/report") {
    config.headers.Accept = '*/*';
  }
  else {
    config.headers.Accept = 'application/json';
  }
  return config;
});

axios.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => {
    // Auto logout when status is unauthorized
    if (err.response?.data?.message === 'jwt expired') {
      NotificationManager.error(
        err.response?.data?.message,
        'Authentication Error',
      );
      localStorage.removeItem(GamesfiCurrentUser.CURRENT_USER);
      setTimeout(() => {
        window.location.pathname = '/';
      }, 1000);
    }
    return Promise.reject(err);
  }
);
export default axios;
